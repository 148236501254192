//In src/components/sub-form.component.js
import React, { useState } from 'react';
import Button from "@material-ui/core/Button"
import TextField from '@material-ui/core/TextField';

const textWrapper = {
    marginBottom: `50px`,
    padding: `5px`,
    borderBottomStyle: 'solid',
    borderColor: `#ff457d`
  }

  const Header = {
    color: `#ff457d`,
  }

  const submitButton = {
    backgroundColor: `#ff457d`,
    color: `white`,
    borderRadius: `0px`,
    marginLeft:`5px`
  }

const SubscriptionForm = () => {
    const [status, setStatus] = useState(null);
    const [email, setEmail] = useState('');

    const bodyText = JSON.stringify({
                    api_key:"Gjzjm61s3N9Fo_Y5N6l28Q",
                    email: email
                    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('text', bodyText)
         await fetch(
                'https://api.convertkit.com/v3/forms/1707538/subscribe',
                {
                    method: 'post',
                    headers: {
                        "Content-Type": "application/json; charset=utf-8"
                    },
                    body: bodyText
            })
            .then(() => setStatus('SUCCESS'))
            .catch (err => {
                setStatus('ERROR')
                console.log(err)
                }
            )
        }

     const handleInputChange = event => {
        const {value} = event.target;
        setEmail(value);
    }

    return (
        <div style={ textWrapper }>
            <h1>{status}</h1>
            {status === 'ERROR' && <h2>Oops, Something went wrong! Pleas refresh the page and try again.</h2>}
                {status === 'SUCCESS' && <h2>Thank you! Don't forget to confirm your subscription. Didn't see an e-mail? Check you spam folder.</h2>}
                {status === null &&
            <div>
            <p>
                Are you enjoying this blog and do you want to receive these articles straight into your inbox?
                Enter your e-mail below and hit the subscribe button!
            </p>
            <form
                className="sub__form"
                name="email_address"
                action={'https://api.convertkit.com/v3/forms/1707538/subscribe'}
                method="post"
                onSubmit={handleSubmit}
                data-netlify="true"
                data-netlify-honeypot="bot-field"
            >
                <input type="hidden" name="form-name" value="email_address" />
                <TextField
                    type="email"
                    aria-label="Your email"
                   //The name attribute should be the same as on you selected form.
                    name="email_address"
                    label="E-Mail"
                    onChange={handleInputChange}
                    value={email}
                    required
                />
                    <br/>
                    <br/>
                <Button style={ submitButton } type="submit">
                    Subscribe
                </Button>

            </form>

            <p className="sub__tag">I won't send you spam and you can unsubscribe at any time</p>
            </div>
            }
        </div>
    );
};

export default SubscriptionForm
