import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../utils/fontawesome'
import { rhythm } from "../utils/typography"
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import styled from 'styled-components'
import NewsletterForm from "../components/ConvertKitForm"

// import Modal from './modal'

const IconWrapper = styled.a`
        &:hover {
            opacity: 0.5;
        }
        padding: 15px;
        textAlign: center;
        textSecoration: none;
        margin: 5px 2px;
        fontSize: 25px;
        textDecoration: none !important; /* no underline */
        color: inherit;

`

const BioWrapper = styled.div`
      display: flex;
      marginBottom: rhythm(2.5);
`

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic2.png/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          bioText
          social {
            twitterPersonal
            twitterSpartan
            facebook
            linkedIn
            instagram
          }
        }
      }
    }
  `)

  const { author, social, bioText } = data.site.siteMetadata
  return (
    <div>
    <BioWrapper>
      <Image
        fadeIn={false}
        loading="eager"
        durationFadeIn={250}
        fixed={data.avatar.childImageSharp.fixed}
        alt={author}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          minWidth: 50,
          borderRadius: `100%`,
          border: `1px solid`
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <p>
        {bioText}
        <a href={`/about-michael-awad`}>
           About Me
        </a>
        <br></br> <br></br>
        <IconWrapper href={`${social.facebook}`}>
          <FontAwesomeIcon icon={faFacebook} />
        </IconWrapper>
        <IconWrapper href={`${social.twitterSpartan}`}>
          <FontAwesomeIcon icon={faTwitter} />
        </IconWrapper>
        <IconWrapper href={`${social.instagram}`}>
          <FontAwesomeIcon icon={faInstagram} />
        </IconWrapper>
          <IconWrapper href={`${social.linkedIn}`}>
          <FontAwesomeIcon icon={faLinkedin} />
        </IconWrapper>
        <IconWrapper href={`${social.github}`}>
          <FontAwesomeIcon icon={faGithub} />
        </IconWrapper>
      </p>
  </BioWrapper>

           <NewsletterForm />

    </div>
  )
}

export default Bio
